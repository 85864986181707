import { graphql } from "gatsby";
import React from "react";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import {
  IKnowledgeHubArticle,
  IKnowledgeHubRelatedArticle,
} from "../../ui/models/interfaces";
import { ArticleContainer } from "../../ui/components";

interface Props {
  data: {
    contentfulKnowledgeHubArticle: IKnowledgeHubArticle;
    relatedArticles: {
      nodes: IKnowledgeHubRelatedArticle[];
    };
  };
  location: any;
}

export default ({ data, location }: Props) => {
  const relatedArticle = data.relatedArticles.nodes;
  const article = data.contentfulKnowledgeHubArticle;

  return (
    <ArticleContainer
      location={location}
      article={article}
      relatedArticles={relatedArticle}
    />
  );
};

export const pageQuery = graphql`
  query KHArticleQuery(
    $contentful_id: String!
    $locale: String!
    $language: String!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulKnowledgeHubArticle(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $locale }
    ) {
      title
      contentful_id
      createdFromNow: createdAt(fromNow: true)
      createdAt(locale: $locale, formatString: "DD MMMM YYYY")
      publishedDate(locale: $locale, formatString: "DD MMMM YYYY")
      author {
        firstName
        lastName
      }
      localContent {
        excerpt {
          raw
        }
        slug
        title
        seoTitle
        seoDescription
        seoImage {
          file {
            url
          }
        }
        content {
          __typename
          raw
          references {
            __typename
            ...KnowledgeHubRichTextReferences
          }
        }
      }
      category {
        title
        featureInNews
        seoMeta {
          slug
          title
        }
      }
      # tags {
      #   knowledgehubarticle {
      #     ...KnowledgeHubArticleCard
      #   }
      # }
      featuredImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              transformOptions: { fit: COVER, trim: 0, cropFocus: CENTER }
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
    relatedArticles: allContentfulKnowledgeHubArticle(
      limit: 3
      filter: {
        tags: {
          elemMatch: {
            knowledgehubarticle: {
              elemMatch: {
                contentful_id: { in: [$contentful_id] }
                localContent: { slug: { ne: null } }
              }
            }
          }
        }
        node_locale: { eq: $locale }
        contentful_id: { ne: $contentful_id }
      }
    ) {
      nodes {
        ...KnowledgeHubArticleCard
      }
    }
  }
`;
